<template>
  <div>
    <div
      class="operationControl"
      style="display: flex; flex-direction: column; align-items: flex-start"
    >
      <div
        style="
          margin-bottom: 15px;
          font-size: 1rem;
          align-items: flex-start;
          padding-left: 0.5rem;
        "
        class="searchbox"
      >
        <span>姓名：{{ row.userName || row.sourceUserName }}</span>
        <span style="margin: 0 15px"
          >身份证号：{{ row.idcard || row.sourceUserIdcard }}</span
        >
        <span>电话：{{ row.mobile || row.sourceUserMobile }}</span>
      </div>
      <div class="searchbox">
        <div title="课程名称" class="searchboxItem">
          <span class="itemLabel">课程名称:</span>
          <el-input
            v-model="courseName"
            type="text"
            size="small"
            placeholder="请输入课程名称"
            clearable
          />
        </div>
        <div title="小节名称" class="searchboxItem">
          <span class="itemLabel">小节名称:</span>
          <el-input
            v-model="kpointName"
            type="text"
            size="small"
            placeholder="请输入小节名称"
            clearable
          />
        </div>
        <div title="学习状态" class="searchboxItem">
          <span class="itemLabel">学习状态:</span>
          <el-select
            v-model="studystate"
            placeholder="请选择学习状态"
            size="small"
            clearable
          >
            <el-option
              v-for="item in studylist"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div>
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
          />
          <el-table-column
            label="课程名称"
            align="center"
            show-overflow-tooltip
            prop="courseName"
            width="350"
          />
          <el-table-column
            label="小节名称"
            align="center"
            show-overflow-tooltip
            prop="kpointName"
            width="300"
          />
          <el-table-column
            label="学习情况"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>
                {{ $setDictionary("LEARNINGSTATE", scope.row.completeState) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="开始学习时间"
            align="center"
            show-overflow-tooltip
            prop="createTime"
            width="200"
          >
            <template slot-scope="scope">{{
              scope.row.createTime | moment
            }}</template>
          </el-table-column>
          <el-table-column
            label="完成时间"
            align="center"
            show-overflow-tooltip
            prop="completeTime"
            width="200"
          >
            <template slot-scope="scope">{{
              scope.row.completeTime | moment
            }}</template>
          </el-table-column>
          <el-table-column
            label="最近观看时间"
            align="center"
            show-overflow-tooltip
            width="200"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.lastTime | moment }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="200px"
            fixed="right"
            v-if="$route.query.stu == 'JG'"
          >
            <div slot-scope="scope">
              <el-button
                style="padding: 0 5px"
                type="text"
                size="mini"
                @click="seeVideoInfo(scope.row)"
                >查看视频记录</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="seeRollCall(scope.row)"
                >查看点名记录</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      courseName: "", // 课程名称
      kpointName: "", // 小节名称
      studystate: "", // 学习状态
      studylist: [], // 学习状态下拉数据
      activeName: "first",
      row: {}, // 缓存
      projectId: "",
    };
  },
  computed: {
    ...mapGetters({
      traintypeList: "getTraintype",
    }),
  },
  created() {},
  methods: {
    init() {
      this.projectId = sessionStorage.getItem("projectIdYw");
      this.getTableHeight();
      this.getData(-1);
      this.getCrowdtypeList();
    },
    // 获取 - 学习状态下拉数据
    getCrowdtypeList() {
      const studylist = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
      for (const key in studylist) {
        if (key != "40") {
          // &&key != "20"
          list.push({
            value: key,
            label: studylist[key],
          });
        }
      }
      this.studylist = list;
    },
    // 获取列表数据
    getData(pageNum) {
      this.row = JSON.parse(sessionStorage.getItem("Learningrecords_row"));
      console.log(this.row);

      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userId: this.row.userId,
        projectId: this.row.projectId || this.projectId,
        completeState: this.studystate,
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.kpointName) {
        params.kpointName = this.kpointName;
      }
      this.doFetch({
        url: "/biz/course/study/v2/queryCourseStudyPage",
        params,
        pageNum,
      });
    },
    // 查看视频记录
    seeVideoInfo(item) {
      this.$router.push({
        path: "/web/classListCom/classDetailsFile/videoInfo",
        query: { studyId: item.studyId },
      });
    },
    // 查看点名记录
    seeRollCall(item) {
      this.$router.push({
        path: "/web/classListCom/classDetailsFile/rollCall",
        query: { studyId: item.studyId },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15 + 30;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
</style>